import React, { useState, useCallback } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'
import tw, { css } from 'twin.macro'

import { SEO } from '@components'
import { MainLayout } from '@common/layouts'

const HERO_BG_COLOR = '#b5b3b2'

const CONDITIONS = {
  mostCommon: [
    'Chronic Pain',
    'Migraines',
    'Cancer',
    'Arthritis',
    'Anxiety',
    'PTSD',
  ],
  all: [
    '• ADD/ADHD',
    '• Alzheimer’s Disease',
    '• Auto Accident(s)',
    '• Back & Neck Problems',
    '• Brain Injury',
    '• Chronic Nausea',
    '• Colitis',
    '• Crohn’s Disease',
    '• Depression',
    '• Eating Disorders',
    '• Epilepsy',
    '• Fibromyalgia',
    '• Gastrointestinal Disorders',
    '• Hepatitis C',
    '• HIV/AIDS',
    '• Irritable Bowel Syndrome',
    '• Kidney Failure/Dialysis',
    '• Migraines',
    '• Multiple Sclerosis',
    '• Muscle Spasms',
    '• Muscular Dystrophy',
    '• Parkinson’s Disease',
    '• Severe Arthritis',
    '• Sexual Dysfunction',
    '• Sleep Disorders',
    '• Spinal Cord Injury/Disease',
  ],
}

const GENERAL_QUESTIONS = [
  {
    question: 'Why do I need a medical document for cannabis?',
    answer:
      'You need a medical document in order to purchase cannabis from a Licensed Producer. Your medical document needs to be approved by a licensed Practitioner when you have an in-person or Telehealth consultation.',
  },
  {
    question:
      'What are the benefits of getting a medical prescription instead of just buying recreationally?',
    answer: `
      There are many reasons to obtain a medical document to buy medical cannabis in Canada. Overall, medical patients vs. recreational buyers:
      <br/><br/>
      <ul>
        <li>• Pay less taxes</li>
        <li>• Have a wider selection of flowers and oils</li>
        <li>• Access a more consistent supply chain</li>
      </ul>
    `,
  },
  {
    question:
      'If I already have a medical prescription, do I need to use the HelloMD Telehealth service?',
    answer:
      'No, you do not. Our service is to obtain a new medical document or to renew an expired one. If you already have an active prescription you can switch Licensed Producers. You’ll need to contact your current LP and have your medical document transferred to the new one.',
  },
  {
    question: 'How long is my online consult with the Practitioner?',
    answer:
      'A typical consult takes between 20-30 minutes. Please be prepared to have your consult in a private, well-lit space, away from distraction. Your Practitioner will also want to discuss any medications you may be on and your prior medical history.',
  },
  {
    question: 'Will I receive advice about cannabis from my Practitioner?',
    answer:
      'Yes. Our Practitioners will guide you in determining if cannabis is the right for you. From the privacy of your own home or office, ask all of your cannabis questions and be sure you’re getting sound medical advice.',
  },
  {
    question: 'What do I do now that I’ve had my consultation?',
    answer:
      'We will send your medical document directly to the Licensed Producer discussed you’ve selected. You’ll need to register for an account with your selected LP and they should contact you when your account has been approved for purchases.',
  },
  {
    question: 'How do I know if I will be approved for medicinal cannabis?',
    answer:
      'Cannabis can be used to treat a number of conditions; however, it’s not guaranteed you will be approved. Cannabis use is not always recommended to patients under the age of 25, those who are pregnant or breastfeeding, or those who are seeking cannabis as a first attempt to treat symptoms.',
  },
  {
    question: 'Will my insurance cover the cost of medical cannabis?',
    answer:
      'Some insurance carriers will cover the cost of cannabis. We suggest checking with your carrier to see if your plan covers it, and making sure your symptoms are on the list of pre-approved conditions.',
  },
  {
    question: 'Why should I choose HelloMD?',
    answer:
      'We have been helping patients understand and make the most of medical cannabis since 2015 throughout Canada and the United States. We have been rated #1 on Trust Pilot, and our Customer Service team is always here to support you through every step of the process.',
  },
]

const Wrapper = styled.section`
  ${tw`flex flex-col`}
`

const Center = styled.div`
  ${tw`relative mx-auto max-w-screen-xl`}
  ${tw`md:(h-auto)`}
`

const HeroBackground = styled(BackgroundImage)`
  ${tw`bg-right-bottom flex items-start justify-center w-full pb-10`}
  ${tw`md:(h-176 py-0 items-center justify-start)`}
  height: 100vw;
`

const HeroTextWrapper = styled.div`
  ${tw`flex flex-col items-center justify-between z-10 px-4 pt-16 text-white`}
  ${tw`md:(absolute items-start inset-0 pt-0 h-full items-start justify-center)`}
`

const HeroTitle = styled.h1`
  ${tw`text-4xl font-bold tracking-wide leading-snug mb-6 text-center`}
  ${tw`md:(text-6xl max-w-md text-left)`}
  ${tw`lg:(max-w-2xl)`}
`

const HeroSubtitle = styled.h3`
  ${tw`text-lg font-semibold tracking-wide leading-snug mb-8 text-center`}
  ${tw`md:(text-2xl max-w-md text-left mb-10)`}
  ${tw`lg:(max-w-xl)`}
`

const Button = styled.a`
  background-color: #ffe373;
  ${tw`flex items-center justify-center text-sm font-semibold min-w-60 h-12 px-4 rounded text-black tracking-wider uppercase border-2 border-transparent outline-none transition-colors duration-200 leading-none cursor-pointer`}
  ${tw`md:(min-w-40)`}
  ${tw`hover:(transition-colors bg-white opacity-100)`}
  ${({ hasborder }) =>
    hasborder &&
    css`
      border-color: #ffe373;
    `}
`

const Title = styled.h3`
  ${tw`text-3xl text-center font-bold mb-12 px-4 leading-snug`}
  ${tw`md:(text-4xl mb-20)`}
`

const Conditions = styled.div`
  background-color: #f7f4f4;
  ${tw`flex flex-col pt-16 pb-8`}
  ${tw`md:(pt-24 pb-10)`}
`

const AllConditions = styled(Conditions)`
  background-color: #eee7e7;
  ${tw`py-8`}
`

const Arrow = styled.i`
  transform: rotate(135deg);
  ${tw`inline-block p-1 border-t-2 border-r-2 border-primary`}
  ${({ isBlack }) => isBlack && tw` border-black `}
  ${({ isUp }) =>
    isUp &&
    css`
      transform: rotate(-45deg);
    `}
`

const MultiColumn = styled.ul`
  @media screen and (min-width: 768px) {
    column-count: 3;
  }
`

const QuestionTitle = styled.h3`
  ${tw`text-lg font-semibold pr-4`}
  ${({ isCurrent }) => isCurrent && tw`text-primary`}
`

const AnswerText = styled.p`
  a {
    ${tw`text-primary`}
  }
`

const BackgroundBottom = styled.div`
  background-color: #f7f4f4;
  ${tw`max-xl:(bg-white)`}
`

const CTAWrapper = styled.div`
  ${tw`flex flex-col flex-wrap items-center`}
`

const SubmenuWrapper = styled.div`
  ${tw`absolute bg-gray-800 text-white z-10`}
`

const SubmenuListItem = styled.li`
  ${tw`p-2 px-4`}
`

const sites = [
  { name: 'Aurora', url: 'https://aurora.hellomd.com' },
  { name: 'Aqualitas', url: 'https://aqualitas.hellomd.com' },
  { name: 'Canna Farms', url: 'https://cannafarms.hellomd.com' },
  { name: 'Cosmic Extracts', url: 'https://cosmic.hellomd.com/' },
  { name: 'MyMedi', url: 'https://mymedi.hellomd.com' },
  { name: 'Mendo', url: 'https://mendo.hellomd.com' },
  { name: 'Starseed Medicinal', url: 'https://starseed.hellomd.com' },
  { name: 'Herbal Dispatch', url: 'https://herbaldispatch.hellomd.com/' },
  { name: 'Tilray', url: 'https://tilray.hellomd.com/' },
  { name: 'ZYUS', url: 'https://zyus.hellomd.com' },
  { name: 'Syndicate', url: 'https://syndicate.hellomd.com' },
  { name: 'Spectrum', url: 'https://ccc.hellomd.com' },
]

const CTAs = ({ hasborder }) => {
  const [menuOpened, setMenuOpened] = useState(false)

  const onClick = () => {
    setMenuOpened((m) => !m)
  }

  return (
    <div tw="text-center">
      {hasborder && <h3 tw="text-2xl font-semibold mb-6">Get Started</h3>}
      <CTAWrapper>
        <Button hasborder={hasborder} onClick={onClick}>
          Get Your Medical Document
        </Button>
      </CTAWrapper>
      {menuOpened && (
        <SubmenuWrapper>
          <ul menuOpened={menuOpened}>
            {sites.map((site) => (
              <SubmenuListItem>
                <a href={site.url}>{site.name}</a>
              </SubmenuListItem>
            ))}
          </ul>
        </SubmenuWrapper>
      )}
    </div>
  )
}

const ArrowIcon = ({ isUp, isBlack }) => (
  <span className={isUp ? 'mt-1/2' : '-mt-1'}>
    <Arrow isUp={isUp} isBlack={isBlack} />
  </span>
)

const GetStartedPage = () => {
  const [isShowingAllConditions, setIsShowingAllConditions] = useState(false)
  const [questionShownIndex, setQuestionShownIndex] = useState(null)

  const openQuestion = useCallback(
    (index) => {
      if (questionShownIndex === index) {
        setQuestionShownIndex(null)
        return
      }

      setQuestionShownIndex(index)
    },
    [questionShownIndex]
  )

  const {
    hero,
    heroMobile,
    bottom,
    iconRegister,
    iconTalk,
    iconFee,
    iconCondition,
  } = useStaticQuery(
    graphql`
      query {
        hero: file(relativePath: { eq: "get-started/hero.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1280) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        heroMobile: file(relativePath: { eq: "get-started/hero-m.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        bottom: file(relativePath: { eq: "get-started/bottom.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        iconRegister: file(
          relativePath: { eq: "get-started/icon-register.svg" }
        ) {
          publicURL
        }
        iconTalk: file(relativePath: { eq: "get-started/icon-talk.svg" }) {
          publicURL
        }
        iconFee: file(relativePath: { eq: "get-started/icon-fee.svg" }) {
          publicURL
        }
        iconCondition: file(
          relativePath: { eq: "get-started/icon-condition.svg" }
        ) {
          publicURL
        }
      }
    `
  )

  return (
    <MainLayout>
      <SEO title="Canada: Get your Medical Cannabis Document online" />

      <Wrapper>
        <div tw="mb-16 md:(mb-24)" style={{ backgroundColor: HERO_BG_COLOR }}>
          <Center>
            <HeroTextWrapper>
              <HeroTitle>
                Ready to Get Your Medical Cannabis Document? It's Online & Easy!
              </HeroTitle>
              <HeroSubtitle>
                Select where you'd like to purchase your medical cannabis:
              </HeroSubtitle>
              <CTAs />
            </HeroTextWrapper>
            <HeroBackground
              Tag="section"
              fluid={[
                heroMobile.childImageSharp.fluid,
                {
                  ...hero.childImageSharp.fluid,
                  media: `(min-width: 768px)`,
                },
              ]}
              backgroundColor={HERO_BG_COLOR}
            />
          </Center>
        </div>

        <Title>How to Get a Medical Cannabis Document in Canada?</Title>

        <Center tw="flex flex-col items-center mb-10 md:(mb-16)">
          <ul tw="flex flex-col justify-between px-4 md:(flex-row)">
            <li tw="flex flex-1 flex-col items-center mb-16">
              <img tw="w-24 mb-8" src={iconRegister.publicURL} alt="" />
              <p tw="text-center px-10 md:(text-lg px-0)">
                Registration can be done 24 hours a day. Fill in your medical
                history and if you need help, talk to us over chat.
              </p>
            </li>
            <li tw="flex flex-1 flex-col items-center mb-16 md:(mx-10)">
              <img tw="w-24 mb-8" src={iconTalk.publicURL} alt="" />
              <p tw="text-center px-10 md:(text-lg px-0)">
                It’s all online. All you need is a video-enabled computer or
                smartphone. It’s easy, private, 100% online. Our licensed
                Practitioners are available for appointments Monday through
                Friday.
              </p>
            </li>
            <li tw="flex flex-1 flex-col items-center mb-16">
              <img tw="w-20 mb-8" src={iconFee.publicURL} alt="" />
              <p tw="text-center px-10 md:(text-lg px-0)">
                Your consultation is free. There are no charges or hidden fees.
              </p>
            </li>
          </ul>

          <CTAs hasborder />
        </Center>

        <div tw="mb-16 md:(mb-32)">
          <Conditions>
            <Center tw="flex flex-col w-full px-4">
              <div tw="flex flex-col w-full items-start justify-between mb-16 md:(flex-row mb-0)">
                <div tw="flex flex-col items-start md:(w-96 mr-16 mb-12)">
                  <h2 tw="text-2xl text-center font-bold mb-16 md:(text-2xl text-left)">
                    Our knowledgeable health practitioners can advise you on how
                    cannabis may help alleviate symptoms related to a number of
                    health conditions, including:
                  </h2>
                </div>
                <ul tw="flex-1 grid grid-cols-1 gap-8 md:(grid-cols-2)">
                  {CONDITIONS['mostCommon'].map((condition) => (
                    <li
                      key={condition}
                      tw="flex items-center text-2xl uppercase font-bold"
                    >
                      <img tw="mr-6" src={iconCondition.publicURL} alt="" />
                      {condition}
                    </li>
                  ))}
                </ul>
              </div>
              <div tw="flex ml-auto uppercase text-primary font-semibold">
                <span
                  tw="flex cursor-pointer p-1 hover:opacity-75"
                  role="button"
                  tabIndex="0"
                  aria-hidden="true"
                  onClick={() => setIsShowingAllConditions((x) => !x)}
                >
                  <span tw="mr-4">
                    {isShowingAllConditions
                      ? 'See less'
                      : 'See All Qualifying Conditions'}
                  </span>
                  <ArrowIcon isUp={isShowingAllConditions} />
                </span>
              </div>
            </Center>
          </Conditions>
          {isShowingAllConditions && (
            <AllConditions>
              <Center tw="w-full px-4">
                <MultiColumn>
                  {CONDITIONS['all'].map((condition) => (
                    <li tw="text-lg font-semibold tracking-wide mb-3">
                      {typeof condition === 'object' ? (
                        <strong tw="font-extrabold tracking-wide leading-relaxed">
                          {condition.title}
                        </strong>
                      ) : (
                        condition
                      )}
                    </li>
                  ))}
                </MultiColumn>
              </Center>
            </AllConditions>
          )}
        </div>

        <div tw="mb-12">
          <Center tw="flex flex-col items-center px-4">
            <Title tw="w-full text-left border-b border-gray-500 mb-0 pb-8 px-0 md:(pb-16)">
              FAQ
            </Title>
            <ul tw="w-full mb-12">
              {GENERAL_QUESTIONS.map(({ question, answer }, index) => {
                const isCurrent = questionShownIndex === index

                return (
                  <li
                    key={index}
                    itemscope
                    itemprop="mainEntity"
                    itemtype="https://schema.org/Question"
                    tw="border-b border-gray-500 py-8 pr-2"
                  >
                    <div
                      onClick={() => openQuestion(index)}
                      role="button"
                      tabIndex="0"
                      aria-hidden="true"
                      tw="flex justify-between items-center cursor-pointer hover:opacity-75"
                    >
                      <QuestionTitle itemprop="name" isCurrent={isCurrent}>
                        {question}
                      </QuestionTitle>
                      <ArrowIcon isUp={isCurrent} isBlack />
                    </div>
                    {isCurrent && (
                      <div
                        itemscope
                        itemprop="acceptedAnswer"
                        itemtype="https://schema.org/Answer"
                        tw="text-lg pt-8"
                      >
                        <AnswerText
                          itemprop="text"
                          dangerouslySetInnerHTML={{
                            __html: answer,
                          }}
                        />
                      </div>
                    )}
                  </li>
                )
              })}
            </ul>
          </Center>
        </div>

        <Center tw="max-w-screen-2xl w-full flex flex-col items-center mb-8 items-stretch xl:(flex-row px-4 mb-16)">
          <div tw="w-full h-full xl:(w-1/2)">
            <HeroBackground fluid={[bottom.childImageSharp.fluid]} />
          </div>
          <BackgroundBottom tw="flex items-center xl:(w-1/2)">
            <div tw="flex flex-col px-4 py-12 items-center md:(px-16)">
              <div tw="xl:(px-16)">
                <h3 tw="text-4xl font-bold text-center mb-6 uppercase">
                  Personalized Advice on the Medical Use of Cannabis
                </h3>
                <p tw="text-center mb-8">
                  Let our health practitioners guide you in determining if
                  cannabis is the right choice for you. From the privacy of your
                  own home or office, ask all of your cannabis questions and be
                  sure you’re getting sound medical advice to help you feel
                  happy and healthy.
                </p>
              </div>
              <CTAs hasborder />
            </div>
          </BackgroundBottom>
        </Center>

        <Center tw="flex w-full items-center justify-center mb-8">
          <iframe
            title="Trustspot widget"
            src="https://trustspot.io/merchant/TrustModule/banner_1/HelloMD?no_cache=1"
            seamless
            allowTransparency="true"
            scrolling="no"
            frameborder="0"
            width="780"
            height="80"
          >
            <p>View HelloMD Reviews On TrustSpot</p>
          </iframe>
        </Center>
      </Wrapper>
    </MainLayout>
  )
}

export default GetStartedPage
